import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import Modal from '../modal';
import './styles.scss';

const CookieDomains = ['localhost', '.pullcontacts.com'];

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);

export const removeCookiePolicy = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicy = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

const CookieConsentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleAcceptAll = () => {
    setCookiePolicy(JSON.stringify({ optional: true }));
    setShowPopup(false);
    //@ts-ignore
    window.dataLayer.push({
      event: 'Permission',
      permission: "granted",
    });
  };

  const handleReject = () => {
    setCookiePolicy(JSON.stringify({ optional: false }));
    setShowPopup(false);
    //@ts-ignore
    window.dataLayer.push({
      event: 'Permission',
      permission: "denied",
    });
  };

  useEffect(() => {
    const cookiePref = getRawCookie('cookie_preference');
    if (cookiePref) {
      const optCookies = JSON.parse(cookiePref);
      if (optCookies?.optional) {
        //@ts-ignore
        window.dataLayer.push({
          event: "Permission",
          permission: "granted",
        });
      }
      setShowPopup(false);
      //@ts-ignore
    } else if (!global?.window?.location?.pathname?.includes('cookie-policy')) {
      setShowPopup(true);
    }
  }, []);

  return showPopup ? (
    <Modal isOpen={showPopup}>
      <div className="msg-box">
        <h2>We respect your personal privacy</h2>
        <p>
          Our team and partners utilize cookies, pixels, and comparable tracking tools (collectively referred to as
          "cookies" here) on our websites. This includes essential cookies for website operation and optional cookies
          for gathering data from you (such as your clicks, cursor movements, and screen recordings on our websites) to
          enhance site functionality, personalize content, conduct usage analytics, and for marketing purposes. By
          selecting "I understand" or continuing to use this website, you agree to our utilization of all cookies. You
          have the option to decline optional cookies by selecting "Do not allow optional cookies".
          <br />
          For more information, please refer to out <Link to="/cookie-policy">Cookie Policy</Link>.
        </p>
        <div className="buttons">
          <div className="clickable-text" onClick={handleReject}>
            Do not allow optional cookies
          </div>
          <button onClick={handleAcceptAll} className="button is-primary is-medium is-rounded">
            I understand
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default CookieConsentPopup;
