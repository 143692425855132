import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import CookieConsentPopup from '../cookie-popup';
import logo from '../../img/logo.svg';
import phone from '../../img/layout/phone.png';
// import topHeader from '../../img/top-header.svg';
// import topHeaderMobile from '../../img/top-header-mobile.svg';
import mobile from '../../img/mobile.svg';
import tab from '../../img/tab.png';
import full from '../../img/full.png';
import mail from '../../img/layout/email.png';
import ogImage from '../../img/pull-contacts.png';
import './styles.scss';

export default ({ children, title, metaName, metaContent, hideIllustrations }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  useEffect(() => {
    const width = global && global.window && global.window.screen && global.window.screen.width;
    if (width < 1300 && width > 991) {
      setIsMobile(false);
      setIsTab(true);
    }
    if (width < 991) {
      setIsMobile(true);
      setIsTab(false);
    }
  }, []);

  return (
    <div className="page-wrap has-text-centered">
      {/* <img
        src={isMobile ? topHeaderMobile : topHeader}
        alt="header"
        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
        onClick={() => window.open('https://promo.hexomatic.com', '_blank')}
      /> */}
       <img src={isMobile ? mobile : isTab ? tab : full} alt='header' style={{width: '100%', height: 'auto', cursor: 'pointer'}} className='cursor-pointer' onClick={() => window.open('https://hexomatic.com', '_blank')}/>
      <section className="section">
        <Helmet>
          <title>{title}</title>
          <meta name={metaName} content={metaContent} />
          <html lang="en" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={metaContent} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:url" content={global.window && global.window.location.href} />
          <meta property="og:image" content={'https://pullcontacts.com/pull-contacts.png'} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={metaContent} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={'https://pullcontacts.com/pull-contacts.png'} />
        </Helmet>
        <div className="logo-container">
          <div className="logo-inner-container">
            <Link to="/">
              <img src={logo} alt="Pull Contacts" />
            </Link>
          </div>
        </div>
        <div className="header">
          <img src={mail} className="mail-icon" />
          <img src={phone} className={`phone-icon ${hideIllustrations ? 'hide' : ''}`} />
        </div>
        <div className="content">{children}</div>
      </section>
      <footer className="footer navbar is-block">
        <div className="footer-links">
          <Link to="/contact">Contact us</Link>
          <Link to="/terms-of-use">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <div className="text-center">&copy; {new Date().getFullYear()} PullContacts.com</div>
      </footer>
      <CookieConsentPopup />
    </div>
  );
};
