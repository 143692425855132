import React from 'react';
import './styles.scss';

export default ({ isOpen, modalToggle, title, onButtonClick, buttonText, children }) => {
  const handleClick = event => {
    return modalToggle();
  };
  return (
    <div className={`modal is-clipped ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        {children || title}
        {buttonText && <div className="modal-content-footer">
          <button
            className="button"
            onClick={() => {
              onButtonClick();
              handleClick();
            }}
          >
            {buttonText}
          </button>
        </div>}
      </div>
      {modalToggle && <button className="modal-close is-large" aria-label="close" onClick={handleClick}></button>}
    </div>
  );
};
